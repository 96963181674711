<template>
  <div class="popup_wrap ca_req_pop" style="width:1150px;height:600px;">
    <!-- popup_wrap :  style="width:900px; height:600px;" 참고용 / 개발시 삭제 해주 세요. . -->
    <button
      type="button"
      class="layer_close"
      @click="openCaReqPop()"
    >
      close
    </button>
    <div class="popup_cont">
      <!-- popup_cont -->

      <h1 class="page_title">{{ $t('msg.ONEX060P012.001') }}</h1><!-- B/L 수정 -->

      <div class="popup_dim" id="ca_req_attach_pop">
        <component
          ref="dext"
          v-if="customComponent"
          :is="customComponent"
          @closeAttach="closeAttachPopup"
          :bl-no="params.blNo"
        />
      </div>

      <div class="content_box">
        <!-- content_box -->
        <!-- 본 건은 양하지(도착지) INBOUND M/F 신고 (현지 수입신고) 시점으로 정정 시 양하지 세관 과태료가 발생할 수 있습니다. <br> -->
        <!-- 패널티 발생 시 안내 메일 발송예정입니다. -->
        <div v-if="reqAreaShow">
          <p v-if="params.mfClosDtYn === 'Y'" v-html="$t('msg.ONEX060P012.002')" />
          <!-- 고객님이 직접 입력하신 사항으로 발생한 손실, 위협 및 비용은 고객님께 귀책 됨에 유의하시어 수정 절차를 진행하여 주시기 바랍니다. <br> -->
          <p class="mt10">
            {{ $t('msg.ONEX060P012.003') }}
            <span class="ml20" v-show="params.caCfmYn === 'Y'">
              <input
                v-model="caReqAll"
                type="checkbox"
                id="caReqAll"
                name="agree"
                @change="checkAgree('A')"
              >
              <label for="caReqAll" v-show="params.caCfmYn === 'Y'"><span></span>{{ $t('msg.ONEX060P012.092') }}</label><!-- 동의 -->
            </span>
          </p>

          <!-- C/A 요청서가 첨부되어야 최종 제출됩니다. -->
          <p
            v-if="params.mfClosDtYn === 'Y'"
            class="mt10"
          >
            {{ $t('msg.ONEX060P012.004') }}
          </p>
          <!-- Original B/L 또는 Surrender B/L 발행 건의 경우, C/A 요청서 제출 대상입니다. <br> -->
          <!-- C/A 요청서 첨부된 건에 한하여 최종 제출 가능합니다. -->
          <p
            v-if="params.mfClosDtYn === 'N'"
            class="mt10"
            v-html="$t('msg.ONEX060P012.005')"
          />
          <!-- ※ B/L 수정 시 주의사항을 충분히 인지하고 수정요청서를 고려해운으로 발송함 -->
          <p class="mt10">
            {{ $t('msg.ONEX060P012.006') }}
            <span class="ml20">
              <input
                v-model="caReqDocToKMTC"
                type="checkbox"
                id="caReqDocToKMTC"
                name="agree"
                @change="checkAgree()"
              >
              <label for="caReqDocToKMTC"><span></span>{{ $t('msg.ONEX060P012.007') }}</label><!-- 동의 -->
            </span>
          </p>
          <!-- C/A로 인하여 잠재적 민형사 또는 행정상 책임과 비용이 발생할 수 있고, 이러한 책임과 비용은 귀하(또는 귀사)가 부담하게 됨을 알려드립니다. -->
          <p class="mt10" v-show="params.caCfmYn === 'Y'">
            {{ $t('msg.ONEX060P012.091') }}
            <span class="ml20">
              <input
                v-model="caReqCnstYn"
                type="checkbox"
                id="caReqCnstYn"
                name="agree"
                @change="checkAgree()"
              >
              <label for="caReqCnstYn"><span></span>{{ $t('msg.ONEX060P012.007') }}</label><!-- 동의 -->
            </span>
          </p>
          <p class="text_center mt10" style="border-bottom:1px solid  #A2AED2">
            <a
              class="button sm"
              style="border-bottom:none; border-radius:5px 5px 0 0;"
              href="#"
              @click.prevent="fnOpenCaReqDoc()"
            >{{ caReqDocOpenYn ? $t('msg.ONEX060P012.008') : $t('msg.ONEX060P012.009') }} <span :class="['btn_icon', caReqDocOpenYn ? 'icoup' : 'icodown']"></span></a><!-- C/A 요청서 닫기 or C/A 요청서 열기 -->
          </p>
        </div>
        <div
          v-show="caReqDocOpenYn"
          class="inner_table capture_area_ca"
          id="capture_area_ca"
        >
          <!-- a 영역 -->
          <div class="text_center position_relative">
            <h2 class="content_title">{{ $t('msg.ONEX060P012.010') }}</h2><!-- B/L CORRECTION REQUEST -->
            <span
              v-if="params.delegateYn === 'N'"
              class="position_absolute"
              style="top:-5px; right:0;"
            >
              <a
                class="button sm btn_print"
                href="#"
                @click.prevent="screenPrint"
              >{{ $t('msg.ONEX060P012.011') }}</a><!-- 인쇄 -->
            </span>
          </div>

          <!-- TO : Korea Marine Transport Co., Ltd -->
          <p class="mt10">
            {{ $t('msg.ONEX060P012.012') }}
          </p>
          <!-- Dear Sirs and Madams<br> -->
          <!-- We hereby request you for the under mentioned corrections -->
          <p class="mt5" v-html="$t('msg.ONEX060P012.013')" />
          <table class="tbl_row t2 mt10">
            <colgroup>
              <col width="160"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.ONEX060P012.014') }}</th><!-- Shipper -->
                <td>{{ caMasterInfoList.length > 0 ? caMasterInfoList[0].shprCstEnm : 'N/A' }}</td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX060P012.015') }}</th><!-- Bill(s) of Lading No -->
                <td>{{ caMasterInfoList.length > 0 ? caMasterInfoList[0].blNo : 'N/A' }}</td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX060P012.016') }}</th><!-- Vessel & Voyage No -->
                <td>{{ caMasterInfoList.length > 0 ? caMasterInfoList[0].vslNameVoy : 'N/A' }}</td>
              </tr>
            </tbody>
          </table>

          <template v-if="blHeader.length > 0">
            <h2 class="content_title">{{ $t('msg.ONEX060P012.017') }}</h2><!-- Shipper/Consignee/Notify -->
            <table class="tbl_row t2 mt10">
              <colgroup>
                <col width="160">
                <col>
                <col>
              </colgroup>
              <thead>
                <tr>
                  <th></th>
                  <th>{{ $t('msg.ONEX060P012.018') }}</th><!-- Original -->
                  <th>{{ $t('msg.ONEX060P012.019') }}</th><!-- Revised -->
                </tr>
              </thead>
              <tbody
                v-for="(header, index) in blHeader"
                :key="index"
              >
                <!------------shipper------------>
                <tr v-if="(header.shprNmOrg || header.shprNmRvs) && (header.shprAdrOrg || header.shprAdrRvs)">
                  <th>Shipper | Name</th>
                  <td>{{ header.shprNmOrg }}</td>
                  <td>{{ header.shprNmRvs }}</td>
                </tr>
                <tr v-else-if="header.shprNmOrg || header.shprNmRvs">
                  <th>Shipper | Name</th>
                  <td>{{ header.shprNmOrg }}</td>
                  <td>{{ header.shprNmRvs }}</td>
                </tr>
                <tr v-if="(header.shprNmOrg || header.shprNmRvs) && (header.shprAdrOrg || header.shprAdrRvs)">
                  <th>Shipper | Address</th>
                  <td class="txt-break font_12"><div v-html="header.shprAdrOrg"></div></td>
                  <td class="txt-break font_12"><div v-html="header.shprAdrRvs"></div></td>
                </tr>
                <tr v-else-if="header.shprAdrOrg || header.shprAdrRvs">
                  <th>Shipper | Address</th>
                  <td class="txt-break font_12"><div v-html="header.shprAdrOrg"></div></td>
                  <td class="txt-break font_12"><div v-html="header.shprAdrRvs"></div></td>
                </tr>
                <!------------consignee------------>
                <tr v-if="(header.cneNmOrg || header.cneNmRvs) && (header.cneAdrOrg || header.cneAdrRvs)">
                  <th>Consignee | Name</th>
                  <td>{{ header.cneNmOrg }}</td>
                  <td>{{ header.cneNmRvs }}</td>
                </tr>
                <tr v-else-if="header.cneNmOrg || header.cneNmRvs">
                  <th>Consignee | Name</th>
                  <td>{{ header.cneNmOrg }}</td>
                  <td>{{ header.cneNmRvs }}</td>
                </tr>
                <tr v-if="(header.cneNmOrg || header.cneNmRvs) && (header.cneAdrOrg || header.cneAdrRvs)">
                  <th>Consignee | Address</th>
                  <td class="txt-break font_12"><div v-html="header.cneAdrOrg"></div></td>
                  <td class="txt-break font_12"><div v-html="header.cneAdrRvs"></div></td>
                </tr>
                <tr v-else-if="header.cneAdrOrg || header.cneAdrRvs">
                  <th>Consignee | Address</th>
                  <td class="txt-break font_12"><div v-html="header.cneAdrOrg"></div></td>
                  <td class="txt-break font_12"><div v-html="header.cneAdrRvs"></div></td>
                </tr>
                <!------------notify------------>
                <tr v-if="(header.ntfNmOrg || header.ntfNmRvs) && (header.ntfAdrOrg || header.ntfAdrRvs)">
                  <th>Notify | Name</th>
                  <td>{{ header.ntfNmOrg }}</td>
                  <td>{{ header.ntfNmRvs }}</td>
                </tr>
                <tr v-else-if="header.ntfNmOrg || header.ntfNmRvs">
                  <th>Notify | Name</th>
                  <td>{{ header.ntfNmOrg }}</td>
                  <td>{{ header.ntfNmRvs }}</td>
                </tr>
                <tr v-if="(header.ntfNmOrg || header.ntfNmRvs) && (header.ntfAdrOrg || header.ntfAdrRvs)">
                  <th>Notify | Address</th>
                  <td class="txt-break font_12"><div v-html="header.ntfAdrOrg"></div></td>
                  <td class="txt-break font_12"><div v-html="header.ntfAdrRvs"></div></td>
                </tr>
                <tr v-else-if="header.ntfAdrOrg || header.ntfAdrRvs">
                  <th>Notify | Address</th>
                  <td class="txt-break font_12"><div v-html="header.ntfAdrOrg"></div></td>
                  <td class="txt-break font_12"><div v-html="header.ntfAdrRvs"></div></td>
                </tr>
                <!------------Measure------------------>
                <tr v-if="header.measureOrg || header.measureRvs">
                  <th>Measure</th>
                  <td>{{ header.measureOrg }}</td>
                  <td>{{ header.measureRvs }}</td>
                </tr>
                <!------------Gross Weight------------------>
                <tr v-if="header.grsWtOrg || header.grsWtRvs">
                  <th>Gross Weight</th>
                  <td>{{ header.grsWtOrg }}</td>
                  <td>{{ header.grsWtRvs }}</td>
                </tr>
                <!-- Package -->
                <tr v-if="(header.pkgCdOrg || header.pkgCdRvs) && (header.pkgQtyOrg || header.pkgQtyRvs)">
                  <th>Package | Qty</th>
                  <td>{{ header.pkgQtyOrg }}</td>
                  <td>{{ header.pkgQtyRvs }}</td>
                </tr>
                <tr v-else-if="header.pkgQtyOrg || header.pkgQtyRvs">
                  <th>Package | Qty</th>
                  <td>{{ header.pkgQtyOrg }}</td>
                  <td>{{ header.pkgQtyRvs }}</td>
                </tr>
                <tr v-if="(header.pkgCdOrg || header.pkgCdRvs) && (header.pkgQtyOrg || header.pkgQtyRvs)">
                  <th>Package | 단위</th>
                  <td>{{ header.pkgCdOrg }}</td>
                  <td>{{ header.pkgCdRvs }}</td>
                </tr>
                <tr v-else-if="header.pkgCdOrg || header.pkgCdRvs">
                  <th>Package | 단위</th>
                  <td>{{ header.pkgCdOrg }}</td>
                  <td>{{ header.pkgCdRvs }}</td>
                </tr>
                <!-- Commodity -->
                <tr v-if="header.cmdtDscOrg || header.cmdtDscRvs">
                  <th>Commodity</th>
                  <td>{{ header.cmdtDscOrg }}</td>
                  <td>{{ header.cmdtDscRvs }}</td>
                </tr>
                <!------------Place of Receipt------------>
                <tr v-if="header.porPortNmOrg || header.porPortNmRvs">
                  <th>Place of Receipt</th>
                  <td>{{ header.porPortNmOrg }}</td>
                  <td>{{ header.porPortNmRvs }}</td>
                </tr>
                <!------------Port of Loading------------>
                <tr v-if="header.polPortNmOrg || header.polPortNmRvs">
                  <th>Port of Loading</th>
                  <td>{{ header.polPortNmOrg }}</td>
                  <td>{{ header.polPortNmRvs }}</td>
                </tr>
                <!------------Port of Discharging------------>
                <tr v-if="header.podPortNmOrg || header.podPortNmRvs">
                  <th>Port of Discharging</th>
                  <td>{{ header.podPortNmOrg }}</td>
                  <td>{{ header.podPortNmRvs }}</td>
                </tr>
                <!------------Place of Delivery------------>
                <tr v-if="header.dlyPortNmOrg || header.dlyPortNmRvs">
                  <th>Place of Delivery</th>
                  <td>{{ header.dlyPortNmOrg }}</td>
                  <td>{{ header.dlyPortNmRvs }}</td>
                </tr>
                <!------------HS Code------------>
                <tr v-if="header.hsCdOrg || header.hsCdRvs">
                  <th>HS Code</th>
                  <td>{{ header.hsCdOrg }}</td>
                  <td>{{ header.hsCdRvs }}</td>
                </tr>
                <tr v-if="header.podHsCdOrg || header.podHsCdRvs">
                  <th>POD HS Code</th>
                  <td>{{ header.podHsCdOrg }}</td>
                  <td>{{ header.podHsCdRvs }}</td>
                </tr>
                <!------------Final Destination------------>
                <tr v-if="header.lstDestPlcNmOrg || header.lstDestPlcNmRvs">
                  <th>Final Destination</th>
                  <td>{{ header.lstDestPlcNmOrg }}</td>
                  <td>{{ header.lstDestPlcNmRvs }}</td>
                </tr>
                <!------------Issue Date------------>
                <tr v-if="header.issDtOrg || header.issDtRvs">
                  <th>Issue Date</th>
                  <td>{{ header.issDtOrg }}</td>
                  <td>{{ header.issDtRvs }}</td>
                </tr>
                <!------------On Board Date------------>
                <tr v-if="header.obrdDtOrg || header.obrdDtRvs">
                  <th>On Board Date</th>
                  <td>{{ header.obrdDtOrg }}</td>
                  <td>{{ header.obrdDtRvs }}</td>
                </tr>
                <!------------Importer's Tax No------------>
                <tr v-if="header.idTaxNoOrg || header.idTaxNoRvs">
                  <th>Importer's Tax No</th>
                  <td>{{ header.idTaxNoOrg }}</td>
                  <td>{{ header.idTaxNoRvs }}</td>
                </tr>
                <!------------Exporter's Tax No------------>
                <tr v-if="header.etTaxNoOrg || header.etTaxNoRvs">
                  <th>Exporter's Tax No</th>
                  <td>{{ header.etTaxNoOrg }}</td>
                  <td>{{ header.etTaxNoRvs }}</td>
                </tr>
                <!------------WASTE/SCRAP Cargo------------>
                <tr v-if="header.wstCgoYnOrg || header.wstCgoYnRvs">
                  <th>WASTE/SCRAP Cargo</th>
                  <td>{{ header.wstCgoYnOrg }}</td>
                  <td>{{ header.wstCgoYnRvs }}</td>
                </tr>
                <!------------License No------------>
                <tr v-if="header.imLicNoOrg || header.imLicNoRvs">
                  <th>License No</th>
                  <td>{{ header.imLicNoOrg }}</td>
                  <td>{{ header.imLicNoRvs }}</td>
                </tr>
                <!------------Bank Guarantee No------------>
                <tr v-if="header.imBnkGurNoOrg || header.imBnkGurNoRvs">
                  <th>Bank Guarantee No</th>
                  <td>{{ header.imBnkGurNoOrg }}</td>
                  <td>{{ header.imBnkGurNoRvs }}</td>
                </tr>
                <!------------IEC No------------>
                <tr v-if="header.iecNoOrg || header.iecNoRvs">
                  <th>IEC No</th>
                  <td>{{ header.iecNoOrg }}</td>
                  <td>{{ header.iecNoRvs }}</td>
                </tr>
                <!------------GST No------------>
                <tr v-if="header.gstNoOrg || header.gstNoRvs">
                  <th>GST No</th>
                  <td>{{ header.gstNoOrg }}</td>
                  <td>{{ header.gstNoRvs }}</td>
                </tr>
                <!------------IEC E-Mail------------>
                <tr v-if="header.iecPicEmlAddrOrg || header.iecPicEmlAddrRvs">
                  <th>IEC E-Mail</th>
                  <td>{{ header.iecPicEmlAddrOrg }}</td>
                  <td>{{ header.iecPicEmlAddrRvs }}</td>
                </tr>
                <!------------Shipper (Country)------------>
                <tr v-if="header.actShprCstCtrCdOrg || header.actShprCstCtrCdRvs">
                  <th>Shipper (Country)</th>
                  <td>{{ header.actShprCstCtrCdOrg }}</td>
                  <td>{{ header.actShprCstCtrCdRvs }}</td>
                </tr>
                <!------------Shipper (City)------------>
                <tr v-if="header.actShprCstCityNmOrg || header.actShprCstCityNmRvs">
                  <th>Shipper (City)</th>
                  <td>{{ header.actShprCstCityNmOrg }}</td>
                  <td>{{ header.actShprCstCityNmRvs }}</td>
                </tr>
                <!------------Shipper (PIC)------------>
                <tr v-if="header.actShprCstPicNmOrg || header.actShprCstPicNmRvs">
                  <th>Shipper (PIC)</th>
                  <td>{{ header.actShprCstPicNmOrg }}</td>
                  <td>{{ header.actShprCstPicNmRvs }}</td>
                </tr>
                <!------------Shipper (PIC Tel)------------>
                <tr v-if="header.actShprCstTelNoOrg || header.actShprCstTelNoRvs">
                  <th>Shipper (PIC Tel)</th>
                  <td>{{ header.actShprCstTelNoOrg }}</td>
                  <td>{{ header.actShprCstTelNoRvs }}</td>
                </tr>
                <!------------Shipper (PIC Fax)------------>
                <tr v-if="header.actShprCstFaxNoOrg || header.actShprCstFaxNoRvs">
                  <th>Shipper (PIC Fax)</th>
                  <td>{{ header.actShprCstFaxNoOrg }}</td>
                  <td>{{ header.actShprCstFaxNoRvs }}</td>
                </tr>
                <!------------Shipper (PIC e-mail)------------>
                <tr v-if="header.actShprCstEmlAddrOrg || header.actShprCstEmlAddrRvs">
                  <th>Shipper (PIC e-mail)</th>
                  <td>{{ header.actShprCstEmlAddrOrg }}</td>
                  <td>{{ header.actShprCstEmlAddrRvs }}</td>
                </tr>
                <!------------Shipper (Company ID)------------>
                <tr v-if="header.actShprIdCatCdOrg || header.actShprIdCatCdRvs">
                  <th>Shipper (Company ID)</th>
                  <td>{{ header.actShprIdCatCdOrg }}</td>
                  <td>{{ header.actShprIdCatCdRvs }}</td>
                </tr>
                <!------------Shipper (Company ID No)------------>
                <tr v-if="header.actShprIdNoOrg || header.actShprIdNoRvs">
                  <th>Shipper (Company ID No)</th>
                  <td>{{ header.actShprIdNoOrg }}</td>
                  <td>{{ header.actShprIdNoRvs }}</td>
                </tr>
                <!------------Consignee (Country)------------>
                <tr v-if="header.cneCstCtrCdOrg || header.cneCstCtrCdRvs">
                  <th>Consignee (Country)</th>
                  <td>{{ header.cneCstCtrCdOrg }}</td>
                  <td>{{ header.cneCstCtrCdRvs }}</td>
                </tr>
                <!------------Consignee (City)------------>
                <tr v-if="header.cneCstCityNmOrg || header.cneCstCityNmRvs">
                  <th>Consignee (City)</th>
                  <td>{{ header.cneCstCityNmOrg }}</td>
                  <td>{{ header.cneCstCityNmRvs }}</td>
                </tr>
                <!------------Consignee (PIC)------------>
                <tr v-if="header.cneCstPicNmOrg || header.cneCstPicNmRvs">
                  <th>Consignee (PIC)</th>
                  <td>{{ header.cneCstPicNmOrg }}</td>
                  <td>{{ header.cneCstPicNmRvs }}</td>
                </tr>
                <!------------Consignee (PIC Tel)------------>
                <tr v-if="header.cneCstTelNoOrg || header.cneCstTelNoRvs">
                  <th>Consignee (PIC Tel)</th>
                  <td>{{ header.cneCstTelNoOrg }}</td>
                  <td>{{ header.cneCstTelNoRvs }}</td>
                </tr>
                <!------------Consignee (PIC Fax)------------>
                <tr v-if="header.cneCstFaxNoOrg || header.cneCstFaxNoRvs">
                  <th>Consignee (PIC Fax)</th>
                  <td>{{ header.cneCstFaxNoOrg }}</td>
                  <td>{{ header.cneCstFaxNoRvs }}</td>
                </tr>
                <!------------Consignee (PIC e-mail)------------>
                <tr v-if="header.cneCstEmlAddrOrg || header.cneCstEmlAddrRvs">
                  <th>Consignee (PIC e-mail)</th>
                  <td>{{ header.cneCstEmlAddrOrg }}</td>
                  <td>{{ header.cneCstEmlAddrRvs }}</td>
                </tr>
                <!------------Consignee (Company ID)------------>
                <tr v-if="header.cneIdCatCdOrg || header.cneIdCatCdRvs">
                  <th>Consignee (Company ID)</th>
                  <td>{{ header.cneIdCatCdOrg }}</td>
                  <td>{{ header.cneIdCatCdRvs }}</td>
                </tr>
                <!------------Consignee (Company ID No)------------>
                <tr v-if="header.cneIdNoOrg || header.cneIdNoRvs">
                  <th>Consignee (Company ID No)</th>
                  <td>{{ header.cneIdNoOrg }}</td>
                  <td>{{ header.cneIdNoRvs }}</td>
                </tr>
                <!------------Notify (Country)------------>
                <tr v-if="header.ntifCstCtrCdOrg || header.ntifCstCtrCdRvs">
                  <th>Notify (Country)</th>
                  <td>{{ header.ntifCstCtrCdOrg }}</td>
                  <td>{{ header.ntifCstCtrCdRvs }}</td>
                </tr>
                <!------------Notify (City)------------>
                <tr v-if="header.ntifCstCityNmOrg || header.ntifCstCityNmRvs">
                  <th>Notify (City)</th>
                  <td>{{ header.ntifCstCityNmOrg }}</td>
                  <td>{{ header.ntifCstCityNmRvs }}</td>
                </tr>
                <!------------Notify (PIC)------------>
                <tr v-if="header.ntifCstPicNmOrg || header.ntifCstPicNmRvs">
                  <th>Notify (PIC)</th>
                  <td>{{ header.ntifCstPicNmOrg }}</td>
                  <td>{{ header.ntifCstPicNmRvs }}</td>
                </tr>
                <!------------Notify (PIC Tel)------------>
                <tr v-if="header.ntifCstTelNoOrg || header.ntifCstTelNoRvs">
                  <th>Notify (PIC Tel)</th>
                  <td>{{ header.ntifCstTelNoOrg }}</td>
                  <td>{{ header.ntifCstTelNoRvs }}</td>
                </tr>
                <!------------Notify (PIC Fax)------------>
                <tr v-if="header.ntifCstFaxNoOrg || header.ntifCstFaxNoRvs">
                  <th>Notify (PIC Fax)</th>
                  <td>{{ header.ntifCstFaxNoOrg }}</td>
                  <td>{{ header.ntifCstFaxNoRvs }}</td>
                </tr>
                <!------------Notify (PIC e-mail)------------>
                <tr v-if="header.ntifCstEmlAddrOrg || header.ntifCstEmlAddrRvs">
                  <th>Notify (PIC e-mail)</th>
                  <td>{{ header.ntifCstEmlAddrOrg }}</td>
                  <td>{{ header.ntifCstEmlAddrRvs }}</td>
                </tr>
                <!------------Notify (Company ID)------------>
                <tr v-if="header.ntifIdCatCdOrg || header.ntifIdCatCdRvs">
                  <th>Notify (Company ID)</th>
                  <td>{{ header.ntifIdCatCdOrg }}</td>
                  <td>{{ header.ntifIdCatCdRvs }}</td>
                </tr>
                <!------------Notify (Company ID No)------------>
                <tr v-if="header.ntifIdNoOrg || header.ntifIdNoRvs">
                  <th>Notify (Company ID No)</th>
                  <td>{{ header.ntifIdNoOrg }}</td>
                  <td>{{ header.ntifIdNoRvs }}</td>
                </tr>
                <!------------Invoice Value Cur------------>
                <tr v-if="header.invValCurCdOrg || header.invValCurCdRvs">
                  <th>Invoice Value Cur</th>
                  <td>{{ header.invValCurCdOrg }}</td>
                  <td>{{ header.invValCurCdRvs }}</td>
                </tr>
                <!------------Invoice Value Exp------------>
                <tr v-if="header.invValExpOrg || header.invValExpRvs">
                  <th>Invoice Value Exp</th>
                  <td>{{ header.invValExpOrg }}</td>
                  <td>{{ header.invValExpRvs }}</td>
                </tr>
                <!------------PAN No------------>
                <tr v-if="header.panNoOrg || header.panNoRvs">
                  <th>PAN No</th>
                  <td>{{ header.panNoOrg }}</td>
                  <td>{{ header.panNoRvs }}</td>
                </tr>
                <!------------Notify's PAN No------------>
                <tr v-if="header.ntifPanNoOrg || header.ntifPanNoRvs">
                  <th>Notify's PAN No</th>
                  <td>{{ header.ntifPanNoOrg }}</td>
                  <td>{{ header.ntifPanNoRvs }}</td>
                </tr>
                <!------------Shipper State/Province------------>
                <tr v-if="header.actShprStateNmOrg || header.actShprStateNmRvs">
                  <th>Shipper State/Province</th>
                  <td>{{ header.actShprStateNmOrg }}</td>
                  <td>{{ header.actShprStateNmRvs }}</td>
                </tr>
                <!------------Shipper Postal Code------------>
                <tr v-if="header.actShprPostNoOrg || header.actShprPostNoRvs">
                  <th>Shipper Postal Code</th>
                  <td>{{ header.actShprPostNoOrg }}</td>
                  <td>{{ header.actShprPostNoRvs }}</td>
                </tr>
                <!------------Consignee State/Province------------>
                <tr v-if="header.cneCstStateNmOrg || header.cneCstStateNmRvs">
                  <th>Consignee State/Province</th>
                  <td>{{ header.cneCstStateNmOrg }}</td>
                  <td>{{ header.cneCstStateNmRvs }}</td>
                </tr>
                <!------------Consignee Postal Code------------>
                <tr v-if="header.cneCstPostNoOrg || header.cneCstPostNoRvs">
                  <th>Consignee Postal Code</th>
                  <td>{{ header.cneCstPostNoOrg }}</td>
                  <td>{{ header.cneCstPostNoRvs }}</td>
                </tr>
                <!------------Notify State/Province------------>
                <tr v-if="header.ntifCstStateNmOrg || header.ntifCstStateNmRvs">
                  <th>Notify State/Province</th>
                  <td>{{ header.ntifCstStateNmOrg }}</td>
                  <td>{{ header.ntifCstStateNmRvs }}</td>
                </tr>
                <!------------Notify Postal Code------------>
                <tr v-if="header.ntifCstPostNoOrg || header.ntifCstPostNoRvs">
                  <th>Notify Postal Code</th>
                  <td>{{ header.ntifCstPostNoOrg }}</td>
                  <td>{{ header.ntifCstPostNoRvs }}</td>
                </tr>
                <!------------Say------------>
                <tr v-if="header.blPrtSntcContOrg || header.blPrtSntcContRvs">
                  <th>Say</th>
                  <td>{{ header.blPrtSntcContOrg }}</td>
                  <td>{{ header.blPrtSntcContRvs }}</td>
                </tr>
                <!------------Importer's ACID No------------>
                <tr v-if="header.acidNoOrg || header.acidNoRvs">
                  <th>Importer's ACID No</th>
                  <td>{{ header.acidNoOrg }}</td>
                  <td>{{ header.acidNoRvs }}</td>
                </tr>
                <!------------Importer's Tax ID------------>
                <tr v-if="header.cneAcidNoOrg || header.cneAcidNoRvs">
                  <th>Importer's Tax ID</th>
                  <td>{{ header.cneAcidNoOrg }}</td>
                  <td>{{ header.cneAcidNoRvs }}</td>
                </tr>
              </tbody>
            </table>
          </template>

          <template v-if="blBody.length > 0">
            <h2 class="content_title">{{ $t('msg.ONEX060P012.020') }}</h2><!-- Marks & Description -->
            <table class="tbl_row t2 mt10">
              <colgroup>
                <col width="160">
                <col>
                <col>
              </colgroup>
              <thead>
                <tr>
                  <th></th>
                  <th>{{ $t('msg.ONEX060P012.018') }}</th><!-- Original -->
                  <th>{{ $t('msg.ONEX060P012.019') }}</th><!-- Revised -->
                </tr>
              </thead>
              <tbody
                v-for="(body,index) in blBody"
                :key="index"
              >
                <tr v-if="body.mrkOrg || body.mrkRvs">
                  <td>{{ body.ordSeqOrg == '1' ? 'Mark' : 'Mark Rider' }}</td>
                  <td class="valign_top"><div class="font_12" v-html="body.mrkOrg"></div></td>
                  <td class="valign_top"><div class="font_12" v-html="body.mrkRvs"></div></td>
                </tr>
                <tr v-if="body.dscrOrg || body.dscrRvs">
                  <td>{{ body.ordSeqOrg == '1' ? 'Description' : 'Description Rider' }}</td>
                  <td class="valign_top"><div class="font_12" v-html="body.dscrOrg"></div></td>
                  <td class="valign_top"><div class="font_12" v-html="body.dscrRvs"></div></td>
                </tr>
              </tbody>
            </table>
          </template>

          <template v-if="blContainer.length > 0">
            <h2 class="content_title">{{ $t('msg.ONEX060P012.021') }}</h2>
            <table class="tbl_col mt10">
              <colgroup>
                <col width="8%">
                <col width="9%">
                <col width="7%">
                <col width="7%">
                <col width="7%">
                <col width="9%">
                <col width="9%">
                <col width="9%">
                <col width="9%">
                <col width="9%">
                <col width="9%">
              </colgroup>
              <thead>
                <tr>
                  <th colspan="2"></th>
                  <th v-html="$t('msg.ONEX060P012.022')" /><!-- Container<br/> No. -->
                  <th>{{ $t('msg.ONEX060P012.023') }}</th><!-- Size -->
                  <th>{{ $t('msg.ONEX060P012.024') }}</th><!-- Type -->
                  <th v-html="$t('msg.ONEX060P012.025')" /><!-- >특수<br/> 화물 -->
                  <th v-html="$t('msg.ONEX060P012.026')" /><!-- 화주<br/> 컨테이너 -->
                  <th>{{ $t('msg.ONEX060P012.027') }}</th><!-- Wgt -->
                  <th>{{ $t('msg.ONEX060P012.028') }}</th><!-- Pkg -->
                  <th>{{ $t('msg.ONEX060P012.029') }}</th><!-- Seal No1. -->
                  <th>{{ $t('msg.ONEX060P012.030') }}</th><!-- Seal No2. -->
                  <th>{{ $t('msg.ONEX060P012.031') }}</th><!-- Seal No3. -->
                  <th>{{ $t('msg.ONEX060P012.032') }}</th><!-- Seal No4. -->
                  <th>{{ $t('msg.ONEX060P012.033') }}</th><!-- Seal No5. -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(container,index) in blContainer"
                  :key="index"
                >
                  <td
                    v-if="index % 2 === 0"
                    rowspan="2"
                  >
                    {{ (index % 2) + 1 }}
                  </td>
                  <td>{{ index % 2 === 0 ? 'Original' : 'Revised' }}</td>
                  <td>{{ container.cntrNo }}</td>
                  <td>{{ container.cntrSzCd }}</td>
                  <td>{{ container.cntrTypCd }}</td>
                  <td>{{ container.cgoTypCd }}</td>
                  <td>{{ container.shprVanYn }}</td>
                  <td>{{ container.wt }}</td>
                  <td>{{ container.pkgQty }}</td>
                  <td>{{ container.sealNo1 }}</td>
                  <td>{{ container.sealNo2 }}</td>
                  <td>{{ container.sealNo3 }}</td>
                  <td>{{ container.sealNo4 }}</td>
                  <td>{{ container.sealNo5 }}</td>
                </tr>
              </tbody>
            </table>
          </template>

          <!-- We undertake and agree to indemnity you in full all consequences and/or liabilities of any kind of what so ever directly or indirectly arising from relating to the said correction <br> -->
          <!-- Your faithfully. -->
          <p class="mt10" v-html="$t('msg.ONEX060P012.034')" v-if="params.caCfmYn !== 'Y'" />

          <template v-if="this.$ekmtcCommon.isNotEmpty(pentAmt) && Number(pentAmt) > 0">
            <!-- * This correction request can bring Customs penalty and the estimated amount is USD {{ pentAmt }}.<br> -->
            <!-- * Please confirm who will pay for it.<br> -->
            <p class="mt10" v-html="$t('msg.ONEX060P012.035', { pentAmt: pentAmt })" />
            <div class="mt10">
              <input
                v-model="shirSacYn"
                type="radio"
                id="rdopentAmt1"
                name="rdopentAmt"
                value="01"
              >
              <label for="rdopentAmt1">{{ $t('msg.ONEX060P012.036') }}</label><!-- by shipper -->
              <input
                v-model="shirSacYn"
                type="radio"
                id="rdopentAmt2"
                name="rdopentAmt"
                value="02"
              >
              <label for="rdopentAmt2">{{ $t('msg.ONEX060P012.037') }}</label><!-- by consignee -->
              <input
                v-model="shirSacYn"
                type="radio"
                id="rdopentAmt3"
                name="rdopentAmt"
                value="03"
              >
              <label for="rdopentAmt3">{{ $t('msg.ONEX060P012.038') }}</label><!-- by consignee to customs directly -->
              <a
                class="button sm ml10"
                href="#"
                @click.prevent="fnInsertPay()"
              >{{ $t('msg.ONEX060P012.039') }}</a><!-- 등록 -->
            </div>
            <!-- * Please confirm B/L No for settlement.<br> {{ blNo }} -->
            <p class="mt10" v-html="$t('msg.ONEX060P012.040', { blNo: caMasterInfoList.length > 0 ? caMasterInfoList[0].blNo : 'N/A' })" />
          </template>

          <!-- Additionally, we hereby confirm that the related penalty amount is to be settled by shipper.<br> -->
          <!-- And the invoice for the penalty can be issued on the B/L No. {{ blNo }} -->
          <p
            v-if="this.$ekmtcCommon.isNotEmpty(sacYn) && sacYn !== 'N'"
            class="mt10"
            v-html="$t('msg.ONEX060P012.041', { blNo: caMasterInfoList.length > 0 ? caMasterInfoList[0].blNo : 'N/A' })"
          />

          <p
            v-if="params.delegateYn === 'Y'"
            class="text_center mt10"
          >
            <span>
              <input
                v-model="submitReqAgreeYn"
                type="checkbox"
                name="no"
                id="submitReqAgreeYn"
              >
              <label for="submitReqAgreeYn"><span class="offscreen">No</span></label>
            </span>
            {{ $t('msg.ONEX060P012.042') }} (<span class="ml10 mr10">{{ caMasterInfoList.length > 0 ? caMasterInfoList[0].actShprCstEnm : 'N/A' }}</span>){{ $t('msg.ONEX060P012.043') }}
          </p>
          <template v-if="params.delegateYn === 'N'">
            <!-- For And behalf of -->
            <p class="mt10 text_right" v-if="params.caCfmYn !== 'Y'">
              {{ $t('msg.ONEX060P012.044') }}
            </p>
            <!-- (실화주 명판, 직인) <br/> -->
            <!-- Date {{ curDate }} -->
            <p class="mt10 text_right" v-if="params.caCfmYn !== 'Y'">
              {{ $t('msg.ONEX060P012.045') }} <br>
              {{ $t('msg.ONEX060P012.046') }} {{ curDate }}
            </p>
          </template>
        </div><!-- a 영역 //-->
      </div><!-- content_box // -->

      <div class="content_box" v-if="reqAreaShow">
        <!-- content_box -->
        <form id="frm_ca_req">
          <h2 class="content_title">{{ $t('msg.ONEX060P012.047') }}</h2><!-- Shipper/Consignee/Notify -->
          <table class="tbl_row">
            <colgroup>
              <col style="width:150px">
              <col>
              <col style="width:150px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">{{ $t('msg.ONEX060P012.048') }}</th><!-- 업체명 -->
                <td>
                  <input
                    v-model="compName"
                    type="text"
                    readonly="readonly"
                  >
                </td>
                <th scope="row">{{ $t('msg.ONEX060P012.049') }}</th><!-- 신청인 -->
                <td>
                  <select
                    v-model="pic.picNm"
                    :class="[pic.picNm === 'direct' ? ['inp_space50_2', 'mr2'] : 'w-100']"
                    name=""
                    id="picNmSel"
                    @change="fnChangeCstInfo($event)"
                  >
                    <option value="">{{ $t('msg.ONEX060P012.050') }}</option><!-- select -->
                    <option value="direct">{{ $t('msg.ONEX060P012.051') }}</option><!-- 직접입력 -->
                    <option
                      v-for="cstInfo in cstInfoList"
                      :key="cstInfo.picNo"
                      :value="cstInfo.picNm"
                    >
                      {{ cstInfo.picNm }}
                    </option>
                  </select>
                  <input
                    v-if="pic.picNm === 'direct'"
                    v-model="pic.picNmDirect"
                    class="inp_space50_2"
                    id="picNm"
                    type="text"
                    @keyup="fnValidationPicNm()"
                  >
                </td>
              </tr>
              <tr>
                <th scope="row">{{ $t('msg.ONEX060P012.052') }}</th><!-- 연락처 -->
                <td>
                  <e-input-number
                    v-model="pic.picTelNo"
                    id="picTelNo"
                    :is-phone="true"
                    @input="fnValidationPicTelNo()"
                  />
                </td>
                <th scope="row">{{ $t('msg.ONEX060P012.053') }}</th><!-- email -->
                <td>
                  <input
                    v-model="pic.picEmlAddr"
                    type="text"
                    id="picEmlAddr"
                    @keyup="fnValidationPicEmlAddr()"
                  >
                </td>
              </tr>
              <tr
                v-if="params.delegateYn === 'N' && params.caCfmYn !== 'Y'"
              >
                <th scope="row">{{ $t('msg.ONEX060P012.054') }}</th><!-- C/A 요청서 Upload -->
                <td colspan="3">
                  <a
                    :class="['button', 'sm', fileAttachYn === 'N' ? 'gray' : 'blue']"
                    id="caReqAttach"
                    href="#"
                    @click.prevent="openAttachPopup('ca-request-attach-pop')"
                  >{{ $t('msg.ONEX060P012.055') }}</a><!-- 파일첨부 -->
                </td>
              </tr>
            </tbody>
          </table>
          <!-- C/A 요청서 제출 업무에 대한 위수임 설정이 되어 있지 않은 경우 실화주 명판, 직인을 득하여 업로드 하기 바랍니다. -->
          <p
            v-if="params.delegateYn === 'N' && !(memberDetail.userCtrCd === 'KR' && $store.getters['auth/getCalcEiCatCd'] === 'O')"
            class="txt_desc"
          >
            {{ $t('msg.ONEX060P012.056') }}
          </p>

          <div
            v-if="params.blIssYn === 'Y' && params.surrYn !== 'Y'"
            class="mt15"
          >
            <!-- 기존 O.B/L 처리방법 선택 -->
            <h2 class="content_title">{{ $t('msg.ONEX060P012.057') }}</h2>
            <!-- (번복 시 처리지연 될 수 있으므로 정확하게 선택해주시기 바랍니다.) -->
            <p class="mt10">{{ $t('msg.ONEX060P012.058') }}</p>
            <div
              class="mt10"
              id="oblCatCd"
            >
              <input
                v-model="pic.oblCatCd"
                type="radio"
                id="rdo1"
                name="rdo"
                value="01"
                @change="fnValidationOblCatCd()"
              >
              <label for="rdo1">{{ $t('msg.ONEX060P012.059') }}</label><!-- O.B/L 재발행(POL) -->
              <!--<input
                v-model="pic.oblCatCd"
                type="radio"
                id="rdo2"
                name="rdo"
                value="02"
                @change="fnValidationOblCatCd()"
              >
              <label for="rdo2">{{ $t('msg.ONEX060P012.060') }}</label> O.B/L 재발행(POD)
              <input
                v-model="pic.oblCatCd"
                type="radio"
                id="rdo3"
                name="rdo"
                value="03"
                @change="fnValidationOblCatCd()"
              >
              <label for="rdo3">{{ $t('msg.ONEX060P012.061') }}</label> O.B/L 재발행(3'RD PLACE) -->
              <input
                v-model="pic.oblCatCd"
                type="radio"
                id="rdo4"
                name="rdo"
                value="04"
                @change="fnValidationOblCatCd()"
              >
              <label for="rdo4">{{ $t('msg.ONEX060P012.062') }}</label><!-- O.B/L NOTICE(전문처리) -->
            </div>
          </div>

          <h2 class="content_title">{{ $t('msg.ONEX060P012.063') }}</h2><!-- 사유 및 요청사항 -->
          <div>
            <textarea
              v-model="pic.rmk"
              id="rmk"
              @keyup="fnValidationRmk()"
            />
          </div>
        </form>
      </div><!-- content_box // -->

      <div class="mt10 text_center">
        <template v-if="reqAreaShow">
          <a class="button blue lg" href="#" @click.prevent="fnCaDelete()">{{ $t('msg.ONEX060P012.065') }}</a><!-- C/A 삭제 -->
          <a class="button blue lg" href="#" @click.prevent="fnCaSave('submit')">{{ $t('msg.ONEX060P012.066') }}</a><!-- C/A Submit -->
          <a class="button blue lg" href="#" @click.prevent="fnCaSave('temp')">{{ $t('msg.MYIN040G010.062') }}</a><!-- Save -->
        </template>
        <a class="button gray lg" href="#" @click.prevent="openCaReqPop()">{{ $t('msg.ONEX060P012.068') }}</a>
      </div>
    </div><!-- popup_cont -->
    <div id="pdf_area">
    </div>
  </div><!-- popup_wrap // -->
</template>

<script>
import { rootComputed } from '@/store/helpers'
import auth from '@/api/services/auth'
import commons from '@/api/services/commons'
import transCommon from '@/api/rest/trans/transCommon'
import ca from '@/api/rest/trans/ca'

export default {
  name: 'CARequestPop',
  components: {
    'ca-request-attach-pop': () => import('@/pages/trans/popup/CARequestAttachPop'),
    'e-input-number': () => import('@/components/common/EInputNumber')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          blIssYn: '',
          surrYn: '',
          mfClosDtYn: '',
          tempCaNo: '',
          isReqAreaShow: true,
          caCfmYn: ''
        }
      }
    }
  },
  data () {
    return {
      // C/A 요청서 첨부 팝업 컴포넌트
      customComponent: null,
      // 오늘 날짜(요청서 안에 들어감.)
      curDate: '',
      // 수정요청서 발송여부
      caReqDocToKMTC: false,
      // 요청서 양식 Display Flag
      caReqDocOpenYn: true,
      caReqAll: false,
      caReqCnstYn: false,
      // 업체명
      compName: '',
      // 신청인 리스트(select box) - 공통함수 호출
      cstInfoList: [],
      reqAreaShow: true,
      params: {
        blNo: '',
        blIssYn: '',
        surrYn: '',
        mfClosDtYn: '',
        tempCaNo: '',
        delegateYn: '',
        uploadFileInfos: []
      },

      // [s] response 데이터
      pic: {
        picNm: '',
        picEmlAddr: '',
        oblCatCd: '',
        rmk: '',
        picTelNo: ''
      },
      caMasterInfoList: [],
      blHeader: [],
      blBody: [],
      blContainer: [],
      pentAmt: '0',
      sacYn: 'N',

      fileAttachYn: 'N',
      // [e] response 데이터

      // pentAmt(예상 패널티 금액) > 0 (패널티 금액이 존재하는 경우)
      // 누가 금액을 지불할 것인지 선택하는 값 (by shipper / by consignee / by consignee to customs directly)
      shirSacYn: '01',
      // 신청인 직접입력 플래그
      directInputYn: 'N',
      // 위수임 설정시(delegateYn === 'Y') 제출 동의여부
      submitReqAgreeYn: ''
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    parentInfo () {
      if (this.$ekmtcCommon.isNotEmpty(this.$props.parentInfo.blNo)) {
        this.setDelegateYn(this.$props.parentInfo.blNo)
      }
    }
  },
  created () {
    this.params = { ...this.params, ...this.parentInfo }
    this.init()
  },
  methods: {
    fnOpenCaReqDoc () {
      this.caReqDocOpenYn = !this.caReqDocOpenYn
    },
    async init () {
      if (this.$ekmtcCommon.isNotEmpty(this.parentInfo.isReqAreaShow)) {
        this.reqAreaShow = this.parentInfo.isReqAreaShow
      }
      this.setDelegateYn(this.parentInfo.blNo)
      this.curDate = this.$ekmtcCommon.getToday().replaceAll('-', '.')
      await auth.info(this.auth.jwt).then(res => {
        const result = res.data
        this.compName = result.compName
      })
      await this.initCstInfoList()
      await this.getCaReqInfo()
    },
    async initCstInfoList () {
      this.cstInfoList = await commons.getCstInfo()
    },
    async getCaReqInfo () {
      if (this.$ekmtcCommon.isNotEmpty(this.params.blNo)) {
        await ca.getCaReqInfo(this.params.blNo)
          .then(response => {
            // console.log(response)
            const pic = response.data.pic
            const caMasterInfoList = response.data.caMasterInfoList
            const blHeader = response.data.blHeader
            const blBody = response.data.blBody
            const blContainer = response.data.blContainer

            if (blHeader !== undefined && blHeader.length > 0) {
              blHeader.forEach(bl => {
                const shprAdrOrg = bl.shprAdrOrg || ''
                const shprAdrRvs = bl.shprAdrRvs || ''
                const cneAdrOrg = bl.cneAdrOrg || ''
                const cneAdrRvs = bl.cneAdrRvs || ''
                const ntfAdrOrg = bl.ntfAdrOrg || ''
                const ntfAdrRvs = bl.ntfAdrRvs || ''

                bl.shprAdrOrg = shprAdrOrg.replace(/\r/gi, '').replace(/\n/gi, '<br>').replace(/ /gi, '&nbsp;')
                bl.shprAdrRvs = shprAdrRvs.replace(/\r/gi, '').replace(/\n/gi, '<br>').replace(/ /gi, '&nbsp;')
                bl.cneAdrOrg = cneAdrOrg.replace(/\r/gi, '').replace(/\n/gi, '<br>').replace(/ /gi, '&nbsp;')
                bl.cneAdrRvs = cneAdrRvs.replace(/\r/gi, '').replace(/\n/gi, '<br>').replace(/ /gi, '&nbsp;')
                bl.ntfAdrOrg = ntfAdrOrg.replace(/\r/gi, '').replace(/\n/gi, '<br>').replace(/ /gi, '&nbsp;')
                bl.ntfAdrRvs = ntfAdrRvs.replace(/\r/gi, '').replace(/\n/gi, '<br>').replace(/ /gi, '&nbsp;')
              })
            }

            if (blBody !== undefined && blBody.length > 0) {
              blBody.forEach(bl => {
                const mrkOrg = bl.mrkOrg || ''
                const mrkRvs = bl.mrkRvs || ''
                const dscrOrg = bl.dscrOrg || ''
                const dscrRvs = bl.dscrRvs || ''
                bl.mrkOrg = mrkOrg.replace(/\r\n/gi, '<br>').replace(/ /gi, '&nbsp;')
                bl.mrkRvs = mrkRvs.replace(/\r\n/gi, '<br>').replace(/ /gi, '&nbsp;')
                bl.dscrOrg = dscrOrg.replace(/\r\n/gi, '<br>').replace(/ /gi, '&nbsp;')
                bl.dscrRvs = dscrRvs.replace(/\r\n/gi, '<br>').replace(/ /gi, '&nbsp;')
              })
            }

            if (pic !== undefined) {
              let directYn = true
              for (let index = 0; index < this.cstInfoList.length; index++) {
                const cstInfo = this.cstInfoList[index]
                if (cstInfo.picNm === pic.picNm) {
                  directYn = !directYn
                  break
                }
              }

              if (directYn) {
                this.pic = {
                  picNm: 'direct',
                  picNmDirect: pic.picNm,
                  picEmlAddr: pic.picEmlAddr,
                  oblCatCd: pic.oblCatCd,
                  rmk: pic.rmk,
                  picTelNo: pic.picTelNo
                }
              } else {
                this.pic = pic
              }
            }
            if (caMasterInfoList !== undefined && caMasterInfoList.length > 0) {
              this.caMasterInfoList = caMasterInfoList

              this.fileAttachYn = caMasterInfoList[0].fileAttachYn
            }
            if (blHeader !== undefined && blHeader.length > 0) {
              this.blHeader = blHeader
            }
            if (blBody !== undefined && blBody.length > 0) {
              this.blBody = blBody
            }
            if (blContainer !== undefined && blContainer.length > 0) {
              this.blContainer = blContainer
            }

            // test
            // this.pic.oblCatCd = '02'

            this.pentAmt = response.data.pentAmt
            // test
            // this.pentAmt = '100'

            this.sacYn = response.data.sacYn
            // test
            // this.sacYn = 'Y'
          })
      }
    },
    async setDelegateYn (blNo) {
      // CD_ID = 01007 (30: 수출 ALL, 32: C/A 요청)
      await transCommon.getBlDelegateYn({ blNo: blNo, arrCstGrpCatCd: ['30', '32'] }).then(async (res) => {
        if (res.data === 'Y') {
          this.params.delegateYn = 'Y'
        } else {
          this.params.delegateYn = 'N'
        }
      })
    },
    fnChangeCstInfo (e) {
      const idx = e.target.selectedIndex - 2

      if (idx >= 0) {
        const picNm = this.$ekmtcCommon.isNotEmpty(this.cstInfoList[idx].picNm) ? this.cstInfoList[idx].picNm : ''
        const telPlcNo = this.$ekmtcCommon.isNotEmpty(this.cstInfoList[idx].telPlcNo) ? this.cstInfoList[idx].telPlcNo : ''
        const telOfcNo = this.$ekmtcCommon.isNotEmpty(this.cstInfoList[idx].telOfcNo) ? this.cstInfoList[idx].telOfcNo : ''
        const picEmlAddr = this.$ekmtcCommon.isNotEmpty(this.cstInfoList[idx].emlAddr) ? this.cstInfoList[idx].emlAddr : ''

        this.pic = {
          picNm: picNm,
          picTelNo: telPlcNo + telOfcNo,
          picEmlAddr: picEmlAddr,
          oblCatCd: this.pic.oblCatCd,
          rmk: this.pic.rmk
        }
      } else {
        this.pic = {
          picNm: '',
          picTelNo: '',
          picEmlAddr: '',
          oblCatCd: this.pic.oblCatCd,
          rmk: this.pic.rmk
        }

        if (e.target.value === 'direct') {
          this.pic = { ...this.pic, ...{ picNm: 'direct', picNmDirect: '' } }
        }
      }

      // this.fnValidationPicNm()
      // this.fnValidationPicTelNo()
      // this.fnValidationPicEmlAddr()
      const frm = document.querySelector('#frm_ca_req')
      this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#picNmSel'))
      this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#picNm'))
      this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#picTelNo'))
      this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#picEmlAddr'))
    },
    // 패널티 금액 지불 대상 등록
    async fnInsertPay () {
      const TH = this

      // 등록하시겠습니까?
      if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.ONEX060P012.069'), useConfirmBtn: true })) {
        const params = {
          blNo: TH.params.blNo,
          pentAmt: Number(TH.pentAmt),
          shirSacYn: TH.shirSacYn
        }

        await ca.updatePay(params).then(response => {
          if (response.headers.respcode === 'C0000') {
            TH.$ekmtcCommon.alertDefault(TH.$t('msg.ONEX060P012.070')) // 등록에 성공하였습니다.
          } else {
            TH.$ekmtcCommon.alertDefault(TH.$t('msg.ONEX060P012.071')) // 등록에 실패하였습니다. 관리자에게 문의해 주세요.
          }
        })
      }
    },
    async screenPrint () {
      const ele = document.querySelector('.capture_area_ca')
      const eleBtnPrint = document.querySelector('.btn_print')
      this.$ekmtcCommon.elemAddClass(ele, 'screen_shot')

      const options = {
        cssSelector: '.capture_area_ca'
      }

      eleBtnPrint.style.display = 'none'

      this.$ekmtcCommon.screenToPrint(options).then(res => {
        if (res === undefined || res === null) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.244'))
        }
        eleBtnPrint.style.display = 'block'
        this.$ekmtcCommon.elemRemoveClass(ele, 'screen_shot')
      }).catch(() => {
        eleBtnPrint.style.display = 'block'
        this.$ekmtcCommon.elemRemoveClass(ele, 'screen_shot')
      })
    },
    // C/A 요청 임시저장/제출
    // flag
    // |_ temp - 임시저장
    // |_ submit - 제출
    async fnCaSave (flag) {
      const TH = this
      let isValidationOk = true

      if (flag === 'submit') {
        isValidationOk = await this.fnValidationAll()
      }

      if (isValidationOk) {
        // 등록하시겠습니까?
        if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.ONEX060P012.069'), useConfirmBtn: true })) {
          let pic = JSON.parse(JSON.stringify(this.pic))

          // 신청인 - '직접입력'인 경우, picNm에 picNmDirect 값을 옮겨줘야 함.
          if (pic.picNm === 'direct') {
            pic = {
              picNm: pic.picNmDirect,
              picEmlAddr: pic.picEmlAddr,
              oblCatCd: pic.oblCatCd,
              rmk: pic.rmk,
              picTelNo: pic.picTelNo
            }
          }

          if (this.params.delegateYn === 'Y' && this.params.caCfmYn !== 'Y') {
            const TH = this
            const ele = document.querySelector('.capture_area_ca')
            TH.$ekmtcCommon.elemAddClass(ele, 'screen_shot')

            await TH.$ekmtcCommon.screenToPdf({ cssSelector: '.capture_area_ca' })
            .then(async (res) => {
              const reqData = new FormData()
              const fileName = `${TH.params.blNo} ca.pdf`
              reqData.append('files', res.output('blob'), fileName)

              await commons.tempFileUpload(reqData).then((result) => {
                  // console.log(result)
                  const fileId = result.data.fileId

                  const fileObj = {
                    fileId: fileId,
                    originalName: fileName
                  }

                  TH.params.uploadFileInfos.push(fileObj)
                })
                .catch(err => {
                  console.log(err)
                })

                TH.$ekmtcCommon.elemRemoveClass(ele, 'screen_shot')
            })
          }

          const params = {
            blNo: this.params.blNo,
            tempFlag: flag === 'temp' ? 'Y' : 'N',
            pic: pic,
            mfClosDtYn: this.params.mfClosDtYn,
            uploadFileInfos: this.params.uploadFileInfos,
            caReqCnstYn: this.caReqAll ? 'Y' : 'N'
          }

          await ca.insertCaReq(params)
            .then(async (response) => {
              if (response.headers.respcode === 'C0000') {
                // 저장하였습니다.

                let message = flag === 'temp' ? TH.$t('msg.ONEX060P012.090') : TH.$t('msg.ONEX060P012.072')

                if (await TH.$ekmtcCommon.asyncAlertMessage({ message: message })) {
                  TH.$emit('selectFunc')
                  TH.$emit('close')
                }
              } else {
                TH.$ekmtcCommon.alertDefault(TH.$t('msg.ONEX060P012.073')) // 저장에 실패하였습니다. 관리자에게 문의해 주세요.
              }
            })
        }
      }
    },
    async fnCaDelete () {
      const TH = this
      let emanYn = this.parentInfo.emanYn

      // 기 요청한 C/A가 삭제됩니다. 삭제하시겠습니까?
      if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.ONEX060P012.074'), useConfirmBtn: true, customConfirmBtnText: TH.$t('msg.ONEX060P012.075') })) {
        if (!emanYn) {
          if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.CSBL200.286'), useConfirmBtn: true, customConfirmBtnText: TH.$t('msg.ONEX060P012.075') })) {
            TH.$emit('child', { type: 'SHAMF' })
            TH.$emit('close')
          }
        } else {
          await ca.deleteCaReq(TH.params.blNo)
            .then(async (response) => {
              if (response.headers.respcode === 'C0000') {
                // 삭제되었습니다.
                if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.ONEX060P012.076') })) {
                  TH.$emit('close')
                }
              } else {
                TH.$ekmtcCommon.alertDefault(TH.$t('msg.ONEX060P012.077')) // 삭제에 실패하였습니다. 관리자에게 문의해 주세요.
              }
            })
        }
      }
    },
    async fnValidationAll () {
      const frm = document.querySelector('#frm_ca_req')
      let isAllOk = true

      if (this.params.caCfmYn === 'Y') {
        if (!await this.fnValidationCaReqAll()) return false
      } else {
        if (!await this.fnValidationCaReqDocToKMTC()) return false
      }
      if (this.params.delegateYn === 'Y' && !await this.fnValidationSubmitReqAgreeYn()) return false
      if (!this.fnValidationPicNm(frm)) {
        isAllOk = false
      }
      if (!this.fnValidationPicTelNo(frm)) {
        isAllOk = false
      }
      if (!this.fnValidationPicEmlAddr(frm)) {
        isAllOk = false
      }
      if (this.params.delegateYn === 'N' && this.params.caCfmYn !== 'Y' && !await this.fnValidationCaReqAttach(frm)) {
        return false
      }
      if (this.params.blIssYn === 'Y' && this.params.surrYn !== 'Y' && !this.fnValidationOblCatCd(frm)) {
        isAllOk = false
      }
      if (!this.fnValidationRmk(frm)) {
        isAllOk = false
      }

      return isAllOk
    },
    // 수정요청서 발송여부 validation
    async fnValidationCaReqDocToKMTC (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_ca_req')
      }

      let isOk = true
      const TH = this

      if (!this.caReqDocToKMTC) {
        const elem = document.querySelector('#caReqDocToKMTC')
        // 발송에 동의 바랍니다.
        if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.ONEX060P012.078') })) {
          isOk = false
          elem.focus()
        }
      }

      return isOk
    },
    async fnValidationCaReqAll () {
      let isOk = true
      const TH = this

      if (!this.caReqDocToKMTC || !this.caReqAll || !this.caReqCnstYn) {
        const elem = document.querySelector('#caReqAll')
        if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.ONEX060P012.093') })) {
          isOk = false
          elem.focus()
        }
      }

      return isOk
    },
    // 위수임 설정시(delegateYn === 'Y') 제출 동의여부 validation
    async fnValidationSubmitReqAgreeYn (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_ca_req')
      }

      let isOk = true
      const TH = this

      if (!this.submitReqAgreeYn) {
        const elem = document.querySelector('#submitReqAgreeYn')
        // 위수임 체크를 해야 제출이 가능합니다.
        if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.ONEX060P012.079') })) {
          isOk = false
          elem.focus()
        }
      }

      return isOk
    },
    // 'Shipper/Consignee/Notify - 신청인' validation
    fnValidationPicNm (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_ca_req')
      }

      let isOk = true
      const selElem = frm.querySelector('#picNmSel')
      const inpElem = frm.querySelector('#picNm')
      let msg = ''

      // 신청인 - '직접입력'시 picNmDirect 체크
      const picNm = this.pic.picNm === 'direct' ? this.pic.picNmDirect : this.pic.picNm

      // 'Shipper/Consignee/Notify - 신청인' 체크
      if (this.$ekmtcCommon.isEmpty(picNm)) {
        isOk = false
        msg = this.pic.picNm === 'direct' ? this.$t('msg.ONEX060P012.080') : this.$t('msg.ONEX060P012.081') // 신청인을 입력해주세요. or 신청인을 선택해주세요.
        if (this.pic.picNm === 'direct') {
          msg = this.$t('msg.ONEX060P012.080') // 신청인을 입력해주세요.
          this.$ekmtcCommon.hideErrorTooltip(selElem)
          this.$ekmtcCommon.showErrorTooltip(inpElem, msg)
        } else {
          msg = this.$t('msg.ONEX060P012.081') // 신청인을 선택해주세요.
          this.$ekmtcCommon.hideErrorTooltip(inpElem)
          this.$ekmtcCommon.showErrorTooltip(selElem, msg)
        }
      } else {
        this.$ekmtcCommon.hideErrorTooltip(selElem)
        this.$ekmtcCommon.hideErrorTooltip(inpElem)
      }

      return isOk
    },
    // 'Shipper/Consignee/Notify - 연락처' validation
    fnValidationPicTelNo (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_ca_req')
      }

      let isOk = true
      let elem = ''
      let msg = ''

      // 'Shipper/Consignee/Notify - 연락처' 체크
      elem = frm.querySelector('#picTelNo')
      if (this.$ekmtcCommon.isEmpty(this.pic.picTelNo)) {
        isOk = false
        msg = this.$t('msg.ONEX060P012.082') // 연락처를 입력해주세요.
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 'Shipper/Consignee/Notify - email' validation
    fnValidationPicEmlAddr (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_ca_req')
      }

      let isOk = true
      let elem = ''
      let msg = ''

      // 'Shipper/Consignee/Notify - email' 체크
      elem = frm.querySelector('#picEmlAddr')
      if (this.$ekmtcCommon.isEmpty(this.pic.picEmlAddr)) {
        isOk = false
        msg = this.$t('msg.ONEX060P012.083') // email를 입력해주세요.
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)

        if (this.pic.picEmlAddr.indexOf(',') > -1) {
          if (!this.$ekmtcCommon.multiCheckEmail(this.pic.picEmlAddr, ',')) {
            isOk = false
            msg = this.$t('msg.ONEX070G110.028') // E-Mail 형식 올바르지 않음.
          }
        } else if (!this.$ekmtcCommon.checkEmail(this.pic.picEmlAddr)) {
          isOk = false
          msg = this.$t('msg.ONEX070G110.028') // E-Mail 형식 올바르지 않음.
        } else {
          if (!this.$ekmtcCommon.checkTextByte(this.pic.picEmlAddr, 100)) {
            isOk = false
            msg = this.$t('msg.ONEX070G110.026') // Byte 초과
          }
        }

        if (!isOk) {
          this.$ekmtcCommon.showErrorTooltip(elem, msg)
        }
      }

      return isOk
    },
    // 'Shipper/Consignee/Notify - C/A 요청서 Upload' validation(위수임 미설정시)
    async fnValidationCaReqAttach (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_ca_req')
      }

      let isOk = true
      const TH = this

      if (this.fileAttachYn === 'N') {
        const elem = document.querySelector('#caReqAttach')
        // CA요청서를 첨부하세요.
        if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.ONEX060P012.084') })) {
          isOk = false
          elem.focus()
        }
      }

      return isOk
    },
    // '기존 O.B/L 처리방법 선택' validation
    fnValidationOblCatCd (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_ca_req')
      }

      let isOk = true
      let elem = ''
      let msg = ''

      // '기존 O.B/L 처리방법 선택' 체크
      elem = frm.querySelector('#oblCatCd')
      if (this.$ekmtcCommon.isEmpty(this.pic.oblCatCd)) {
        isOk = false
        msg = this.$t('msg.ONEX060P012.085') // 기존 O.B/L 처리방법 선택해주세요.
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // '사유 및 요청사항' validation
    fnValidationRmk (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_ca_req')
      }

      let isOk = true
      let elem = ''
      let msg = ''

      // '사유 및 요청사항' 체크
      elem = frm.querySelector('#rmk')
      if (!this.$ekmtcCommon.checkTextByte(this.pic.rmk, 2000)) {
        isOk = false
        msg = this.$t('msg.ONEX060P012.086') // 내용이 너무 많습니다.
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 요청서 첨부 팝업 열기
    openAttachPopup (compNm) {
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.ca_req_pop .popup_dim')
    },
    // 요청서 첨부 팝업 닫기
    closeAttachPopup (size) {
      // console.log(size)
      const elemAttachPopup = document.querySelector('#ca_req_attach_pop')
      this.customComponent = null
      // this.selectFunc = null

      elemAttachPopup.style.display = 'none'

      this.$ekmtcCommon.layerClose('.ca_req_pop .popup_dim')

      if (size !== undefined) {
        this.fileAttachYn = size > 0 ? 'Y' : 'N'
      }
    },
    // C/A 요청팝업 닫기
    async openCaReqPop () {
      const TH = this

      // 작성한 내용은 저장되지 않습니다. 팝업을 닫으시겠습니까?
      if (this.reqAreaShow) {
        if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.ONEX060P012.087'), useConfirmBtn: true })) {
          TH.$emit('close')
        }
      } else {
        TH.$emit('close')
      }
    },
    // 위수임 바로가기
    async moveDelegateReqPage () {
      const TH = this

      // 위수임 신청 페이지로 이동하시겠습니까?
      if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.ONEX060P012.089'), useConfirmBtn: true })) {
        TH.$emit('close')
        TH.$router.push({ name: 'import-business-agency-information-main' })
      }
    },
    checkAgree (type) {
      if (this.params.caCfmYn === 'Y') {
        if (type === 'A') {
          if (this.caReqAll) {
            this.caReqDocToKMTC = true
            this.caReqCnstYn = true
          } else {
            this.caReqDocToKMTC = false
            this.caReqCnstYn = false
          }
        } else {
          if (this.caReqDocToKMTC && this.caReqCnstYn) {
            this.caReqAll = true
          } else {
            this.caReqAll = false
          }
        }
      }
    }
  }
}
</script>
<style scoped>
  .txt-break {word-break: break-word;}
</style>
