var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap ca_req_pop",
      staticStyle: { width: "1150px", height: "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.openCaReqPop()
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.001"))),
        ]),
        _c(
          "div",
          { staticClass: "popup_dim", attrs: { id: "ca_req_attach_pop" } },
          [
            _vm.customComponent
              ? _c(_vm.customComponent, {
                  ref: "dext",
                  tag: "component",
                  attrs: { "bl-no": _vm.params.blNo },
                  on: { closeAttach: _vm.closeAttachPopup },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "content_box" }, [
          _vm.reqAreaShow
            ? _c("div", [
                _vm.params.mfClosDtYn === "Y"
                  ? _c("p", {
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("msg.ONEX060P012.002")),
                      },
                    })
                  : _vm._e(),
                _c("p", { staticClass: "mt10" }, [
                  _vm._v(" " + _vm._s(_vm.$t("msg.ONEX060P012.003")) + " "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.params.caCfmYn === "Y",
                          expression: "params.caCfmYn === 'Y'",
                        },
                      ],
                      staticClass: "ml20",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.caReqAll,
                            expression: "caReqAll",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "caReqAll",
                          name: "agree",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.caReqAll)
                            ? _vm._i(_vm.caReqAll, null) > -1
                            : _vm.caReqAll,
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.caReqAll,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.caReqAll = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.caReqAll = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.caReqAll = $$c
                              }
                            },
                            function ($event) {
                              return _vm.checkAgree("A")
                            },
                          ],
                        },
                      }),
                      _c(
                        "label",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.params.caCfmYn === "Y",
                              expression: "params.caCfmYn === 'Y'",
                            },
                          ],
                          attrs: { for: "caReqAll" },
                        },
                        [
                          _c("span"),
                          _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.092"))),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm.params.mfClosDtYn === "Y"
                  ? _c("p", { staticClass: "mt10" }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.ONEX060P012.004")) + " "),
                    ])
                  : _vm._e(),
                _vm.params.mfClosDtYn === "N"
                  ? _c("p", {
                      staticClass: "mt10",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("msg.ONEX060P012.005")),
                      },
                    })
                  : _vm._e(),
                _c("p", { staticClass: "mt10" }, [
                  _vm._v(" " + _vm._s(_vm.$t("msg.ONEX060P012.006")) + " "),
                  _c("span", { staticClass: "ml20" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.caReqDocToKMTC,
                          expression: "caReqDocToKMTC",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "caReqDocToKMTC",
                        name: "agree",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.caReqDocToKMTC)
                          ? _vm._i(_vm.caReqDocToKMTC, null) > -1
                          : _vm.caReqDocToKMTC,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.caReqDocToKMTC,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.caReqDocToKMTC = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.caReqDocToKMTC = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.caReqDocToKMTC = $$c
                            }
                          },
                          function ($event) {
                            return _vm.checkAgree()
                          },
                        ],
                      },
                    }),
                    _c("label", { attrs: { for: "caReqDocToKMTC" } }, [
                      _c("span"),
                      _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.007"))),
                    ]),
                  ]),
                ]),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.params.caCfmYn === "Y",
                        expression: "params.caCfmYn === 'Y'",
                      },
                    ],
                    staticClass: "mt10",
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("msg.ONEX060P012.091")) + " "),
                    _c("span", { staticClass: "ml20" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.caReqCnstYn,
                            expression: "caReqCnstYn",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "caReqCnstYn",
                          name: "agree",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.caReqCnstYn)
                            ? _vm._i(_vm.caReqCnstYn, null) > -1
                            : _vm.caReqCnstYn,
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.caReqCnstYn,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.caReqCnstYn = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.caReqCnstYn = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.caReqCnstYn = $$c
                              }
                            },
                            function ($event) {
                              return _vm.checkAgree()
                            },
                          ],
                        },
                      }),
                      _c("label", { attrs: { for: "caReqCnstYn" } }, [
                        _c("span"),
                        _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.007"))),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "text_center mt10",
                    staticStyle: { "border-bottom": "1px solid  #A2AED2" },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "button sm",
                        staticStyle: {
                          "border-bottom": "none",
                          "border-radius": "5px 5px 0 0",
                        },
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.fnOpenCaReqDoc()
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.caReqDocOpenYn
                              ? _vm.$t("msg.ONEX060P012.008")
                              : _vm.$t("msg.ONEX060P012.009")
                          ) + " "
                        ),
                        _c("span", {
                          class: [
                            "btn_icon",
                            _vm.caReqDocOpenYn ? "icoup" : "icodown",
                          ],
                        }),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.caReqDocOpenYn,
                  expression: "caReqDocOpenYn",
                },
              ],
              staticClass: "inner_table capture_area_ca",
              attrs: { id: "capture_area_ca" },
            },
            [
              _c("div", { staticClass: "text_center position_relative" }, [
                _c("h2", { staticClass: "content_title" }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.010"))),
                ]),
                _vm.params.delegateYn === "N"
                  ? _c(
                      "span",
                      {
                        staticClass: "position_absolute",
                        staticStyle: { top: "-5px", right: "0" },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "button sm btn_print",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.screenPrint.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("msg.ONEX060P012.011")))]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c("p", { staticClass: "mt10" }, [
                _vm._v(" " + _vm._s(_vm.$t("msg.ONEX060P012.012")) + " "),
              ]),
              _c("p", {
                staticClass: "mt5",
                domProps: { innerHTML: _vm._s(_vm.$t("msg.ONEX060P012.013")) },
              }),
              _c("table", { staticClass: "tbl_row t2 mt10" }, [
                _vm._m(0),
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX060P012.014")))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.caMasterInfoList.length > 0
                            ? _vm.caMasterInfoList[0].shprCstEnm
                            : "N/A"
                        )
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX060P012.015")))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.caMasterInfoList.length > 0
                            ? _vm.caMasterInfoList[0].blNo
                            : "N/A"
                        )
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX060P012.016")))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.caMasterInfoList.length > 0
                            ? _vm.caMasterInfoList[0].vslNameVoy
                            : "N/A"
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm.blHeader.length > 0
                ? [
                    _c("h2", { staticClass: "content_title" }, [
                      _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.017"))),
                    ]),
                    _c(
                      "table",
                      { staticClass: "tbl_row t2 mt10" },
                      [
                        _vm._m(1),
                        _c("thead", [
                          _c("tr", [
                            _c("th"),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.018"))),
                            ]),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.019"))),
                            ]),
                          ]),
                        ]),
                        _vm._l(_vm.blHeader, function (header, index) {
                          return _c("tbody", { key: index }, [
                            (header.shprNmOrg || header.shprNmRvs) &&
                            (header.shprAdrOrg || header.shprAdrRvs)
                              ? _c("tr", [
                                  _c("th", [_vm._v("Shipper | Name")]),
                                  _c("td", [_vm._v(_vm._s(header.shprNmOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.shprNmRvs))]),
                                ])
                              : header.shprNmOrg || header.shprNmRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Shipper | Name")]),
                                  _c("td", [_vm._v(_vm._s(header.shprNmOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.shprNmRvs))]),
                                ])
                              : _vm._e(),
                            (header.shprNmOrg || header.shprNmRvs) &&
                            (header.shprAdrOrg || header.shprAdrRvs)
                              ? _c("tr", [
                                  _c("th", [_vm._v("Shipper | Address")]),
                                  _c(
                                    "td",
                                    { staticClass: "txt-break font_12" },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(header.shprAdrOrg),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "txt-break font_12" },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(header.shprAdrRvs),
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              : header.shprAdrOrg || header.shprAdrRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Shipper | Address")]),
                                  _c(
                                    "td",
                                    { staticClass: "txt-break font_12" },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(header.shprAdrOrg),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "txt-break font_12" },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(header.shprAdrRvs),
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            (header.cneNmOrg || header.cneNmRvs) &&
                            (header.cneAdrOrg || header.cneAdrRvs)
                              ? _c("tr", [
                                  _c("th", [_vm._v("Consignee | Name")]),
                                  _c("td", [_vm._v(_vm._s(header.cneNmOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.cneNmRvs))]),
                                ])
                              : header.cneNmOrg || header.cneNmRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Consignee | Name")]),
                                  _c("td", [_vm._v(_vm._s(header.cneNmOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.cneNmRvs))]),
                                ])
                              : _vm._e(),
                            (header.cneNmOrg || header.cneNmRvs) &&
                            (header.cneAdrOrg || header.cneAdrRvs)
                              ? _c("tr", [
                                  _c("th", [_vm._v("Consignee | Address")]),
                                  _c(
                                    "td",
                                    { staticClass: "txt-break font_12" },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(header.cneAdrOrg),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "txt-break font_12" },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(header.cneAdrRvs),
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              : header.cneAdrOrg || header.cneAdrRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Consignee | Address")]),
                                  _c(
                                    "td",
                                    { staticClass: "txt-break font_12" },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(header.cneAdrOrg),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "txt-break font_12" },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(header.cneAdrRvs),
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            (header.ntfNmOrg || header.ntfNmRvs) &&
                            (header.ntfAdrOrg || header.ntfAdrRvs)
                              ? _c("tr", [
                                  _c("th", [_vm._v("Notify | Name")]),
                                  _c("td", [_vm._v(_vm._s(header.ntfNmOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.ntfNmRvs))]),
                                ])
                              : header.ntfNmOrg || header.ntfNmRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Notify | Name")]),
                                  _c("td", [_vm._v(_vm._s(header.ntfNmOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.ntfNmRvs))]),
                                ])
                              : _vm._e(),
                            (header.ntfNmOrg || header.ntfNmRvs) &&
                            (header.ntfAdrOrg || header.ntfAdrRvs)
                              ? _c("tr", [
                                  _c("th", [_vm._v("Notify | Address")]),
                                  _c(
                                    "td",
                                    { staticClass: "txt-break font_12" },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(header.ntfAdrOrg),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "txt-break font_12" },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(header.ntfAdrRvs),
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              : header.ntfAdrOrg || header.ntfAdrRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Notify | Address")]),
                                  _c(
                                    "td",
                                    { staticClass: "txt-break font_12" },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(header.ntfAdrOrg),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "txt-break font_12" },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(header.ntfAdrRvs),
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            header.measureOrg || header.measureRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Measure")]),
                                  _c("td", [_vm._v(_vm._s(header.measureOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.measureRvs))]),
                                ])
                              : _vm._e(),
                            header.grsWtOrg || header.grsWtRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Gross Weight")]),
                                  _c("td", [_vm._v(_vm._s(header.grsWtOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.grsWtRvs))]),
                                ])
                              : _vm._e(),
                            (header.pkgCdOrg || header.pkgCdRvs) &&
                            (header.pkgQtyOrg || header.pkgQtyRvs)
                              ? _c("tr", [
                                  _c("th", [_vm._v("Package | Qty")]),
                                  _c("td", [_vm._v(_vm._s(header.pkgQtyOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.pkgQtyRvs))]),
                                ])
                              : header.pkgQtyOrg || header.pkgQtyRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Package | Qty")]),
                                  _c("td", [_vm._v(_vm._s(header.pkgQtyOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.pkgQtyRvs))]),
                                ])
                              : _vm._e(),
                            (header.pkgCdOrg || header.pkgCdRvs) &&
                            (header.pkgQtyOrg || header.pkgQtyRvs)
                              ? _c("tr", [
                                  _c("th", [_vm._v("Package | 단위")]),
                                  _c("td", [_vm._v(_vm._s(header.pkgCdOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.pkgCdRvs))]),
                                ])
                              : header.pkgCdOrg || header.pkgCdRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Package | 단위")]),
                                  _c("td", [_vm._v(_vm._s(header.pkgCdOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.pkgCdRvs))]),
                                ])
                              : _vm._e(),
                            header.cmdtDscOrg || header.cmdtDscRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Commodity")]),
                                  _c("td", [_vm._v(_vm._s(header.cmdtDscOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.cmdtDscRvs))]),
                                ])
                              : _vm._e(),
                            header.porPortNmOrg || header.porPortNmRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Place of Receipt")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.porPortNmOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.porPortNmRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.polPortNmOrg || header.polPortNmRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Port of Loading")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.polPortNmOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.polPortNmRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.podPortNmOrg || header.podPortNmRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Port of Discharging")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.podPortNmOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.podPortNmRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.dlyPortNmOrg || header.dlyPortNmRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Place of Delivery")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.dlyPortNmOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.dlyPortNmRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.hsCdOrg || header.hsCdRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("HS Code")]),
                                  _c("td", [_vm._v(_vm._s(header.hsCdOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.hsCdRvs))]),
                                ])
                              : _vm._e(),
                            header.podHsCdOrg || header.podHsCdRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("POD HS Code")]),
                                  _c("td", [_vm._v(_vm._s(header.podHsCdOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.podHsCdRvs))]),
                                ])
                              : _vm._e(),
                            header.lstDestPlcNmOrg || header.lstDestPlcNmRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Final Destination")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.lstDestPlcNmOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.lstDestPlcNmRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.issDtOrg || header.issDtRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Issue Date")]),
                                  _c("td", [_vm._v(_vm._s(header.issDtOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.issDtRvs))]),
                                ])
                              : _vm._e(),
                            header.obrdDtOrg || header.obrdDtRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("On Board Date")]),
                                  _c("td", [_vm._v(_vm._s(header.obrdDtOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.obrdDtRvs))]),
                                ])
                              : _vm._e(),
                            header.idTaxNoOrg || header.idTaxNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Importer's Tax No")]),
                                  _c("td", [_vm._v(_vm._s(header.idTaxNoOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.idTaxNoRvs))]),
                                ])
                              : _vm._e(),
                            header.etTaxNoOrg || header.etTaxNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Exporter's Tax No")]),
                                  _c("td", [_vm._v(_vm._s(header.etTaxNoOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.etTaxNoRvs))]),
                                ])
                              : _vm._e(),
                            header.wstCgoYnOrg || header.wstCgoYnRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("WASTE/SCRAP Cargo")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.wstCgoYnOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.wstCgoYnRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.imLicNoOrg || header.imLicNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("License No")]),
                                  _c("td", [_vm._v(_vm._s(header.imLicNoOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.imLicNoRvs))]),
                                ])
                              : _vm._e(),
                            header.imBnkGurNoOrg || header.imBnkGurNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Bank Guarantee No")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.imBnkGurNoOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.imBnkGurNoRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.iecNoOrg || header.iecNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("IEC No")]),
                                  _c("td", [_vm._v(_vm._s(header.iecNoOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.iecNoRvs))]),
                                ])
                              : _vm._e(),
                            header.gstNoOrg || header.gstNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("GST No")]),
                                  _c("td", [_vm._v(_vm._s(header.gstNoOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.gstNoRvs))]),
                                ])
                              : _vm._e(),
                            header.iecPicEmlAddrOrg || header.iecPicEmlAddrRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("IEC E-Mail")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.iecPicEmlAddrOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.iecPicEmlAddrRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.actShprCstCtrCdOrg ||
                            header.actShprCstCtrCdRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Shipper (Country)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.actShprCstCtrCdOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.actShprCstCtrCdRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.actShprCstCityNmOrg ||
                            header.actShprCstCityNmRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Shipper (City)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.actShprCstCityNmOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.actShprCstCityNmRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.actShprCstPicNmOrg ||
                            header.actShprCstPicNmRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Shipper (PIC)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.actShprCstPicNmOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.actShprCstPicNmRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.actShprCstTelNoOrg ||
                            header.actShprCstTelNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Shipper (PIC Tel)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.actShprCstTelNoOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.actShprCstTelNoRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.actShprCstFaxNoOrg ||
                            header.actShprCstFaxNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Shipper (PIC Fax)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.actShprCstFaxNoOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.actShprCstFaxNoRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.actShprCstEmlAddrOrg ||
                            header.actShprCstEmlAddrRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Shipper (PIC e-mail)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.actShprCstEmlAddrOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.actShprCstEmlAddrRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.actShprIdCatCdOrg || header.actShprIdCatCdRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Shipper (Company ID)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.actShprIdCatCdOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.actShprIdCatCdRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.actShprIdNoOrg || header.actShprIdNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Shipper (Company ID No)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.actShprIdNoOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.actShprIdNoRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.cneCstCtrCdOrg || header.cneCstCtrCdRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Consignee (Country)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.cneCstCtrCdOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.cneCstCtrCdRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.cneCstCityNmOrg || header.cneCstCityNmRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Consignee (City)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.cneCstCityNmOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.cneCstCityNmRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.cneCstPicNmOrg || header.cneCstPicNmRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Consignee (PIC)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.cneCstPicNmOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.cneCstPicNmRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.cneCstTelNoOrg || header.cneCstTelNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Consignee (PIC Tel)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.cneCstTelNoOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.cneCstTelNoRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.cneCstFaxNoOrg || header.cneCstFaxNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Consignee (PIC Fax)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.cneCstFaxNoOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.cneCstFaxNoRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.cneCstEmlAddrOrg || header.cneCstEmlAddrRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Consignee (PIC e-mail)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.cneCstEmlAddrOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.cneCstEmlAddrRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.cneIdCatCdOrg || header.cneIdCatCdRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Consignee (Company ID)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.cneIdCatCdOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.cneIdCatCdRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.cneIdNoOrg || header.cneIdNoRvs
                              ? _c("tr", [
                                  _c("th", [
                                    _vm._v("Consignee (Company ID No)"),
                                  ]),
                                  _c("td", [_vm._v(_vm._s(header.cneIdNoOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.cneIdNoRvs))]),
                                ])
                              : _vm._e(),
                            header.ntifCstCtrCdOrg || header.ntifCstCtrCdRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Notify (Country)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifCstCtrCdOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifCstCtrCdRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.ntifCstCityNmOrg || header.ntifCstCityNmRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Notify (City)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifCstCityNmOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifCstCityNmRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.ntifCstPicNmOrg || header.ntifCstPicNmRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Notify (PIC)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifCstPicNmOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifCstPicNmRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.ntifCstTelNoOrg || header.ntifCstTelNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Notify (PIC Tel)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifCstTelNoOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifCstTelNoRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.ntifCstFaxNoOrg || header.ntifCstFaxNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Notify (PIC Fax)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifCstFaxNoOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifCstFaxNoRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.ntifCstEmlAddrOrg || header.ntifCstEmlAddrRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Notify (PIC e-mail)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifCstEmlAddrOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifCstEmlAddrRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.ntifIdCatCdOrg || header.ntifIdCatCdRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Notify (Company ID)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifIdCatCdOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifIdCatCdRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.ntifIdNoOrg || header.ntifIdNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Notify (Company ID No)")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifIdNoOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifIdNoRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.invValCurCdOrg || header.invValCurCdRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Invoice Value Cur")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.invValCurCdOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.invValCurCdRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.invValExpOrg || header.invValExpRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Invoice Value Exp")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.invValExpOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.invValExpRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.panNoOrg || header.panNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("PAN No")]),
                                  _c("td", [_vm._v(_vm._s(header.panNoOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.panNoRvs))]),
                                ])
                              : _vm._e(),
                            header.ntifPanNoOrg || header.ntifPanNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Notify's PAN No")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifPanNoOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifPanNoRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.actShprStateNmOrg || header.actShprStateNmRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Shipper State/Province")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.actShprStateNmOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.actShprStateNmRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.actShprPostNoOrg || header.actShprPostNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Shipper Postal Code")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.actShprPostNoOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.actShprPostNoRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.cneCstStateNmOrg || header.cneCstStateNmRvs
                              ? _c("tr", [
                                  _c("th", [
                                    _vm._v("Consignee State/Province"),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.cneCstStateNmOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.cneCstStateNmRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.cneCstPostNoOrg || header.cneCstPostNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Consignee Postal Code")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.cneCstPostNoOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.cneCstPostNoRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.ntifCstStateNmOrg || header.ntifCstStateNmRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Notify State/Province")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifCstStateNmOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifCstStateNmRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.ntifCstPostNoOrg || header.ntifCstPostNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Notify Postal Code")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifCstPostNoOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.ntifCstPostNoRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.blPrtSntcContOrg || header.blPrtSntcContRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Say")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.blPrtSntcContOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.blPrtSntcContRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                            header.acidNoOrg || header.acidNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Importer's ACID No")]),
                                  _c("td", [_vm._v(_vm._s(header.acidNoOrg))]),
                                  _c("td", [_vm._v(_vm._s(header.acidNoRvs))]),
                                ])
                              : _vm._e(),
                            header.cneAcidNoOrg || header.cneAcidNoRvs
                              ? _c("tr", [
                                  _c("th", [_vm._v("Importer's Tax ID")]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.cneAcidNoOrg)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(header.cneAcidNoRvs)),
                                  ]),
                                ])
                              : _vm._e(),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]
                : _vm._e(),
              _vm.blBody.length > 0
                ? [
                    _c("h2", { staticClass: "content_title" }, [
                      _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.020"))),
                    ]),
                    _c(
                      "table",
                      { staticClass: "tbl_row t2 mt10" },
                      [
                        _vm._m(2),
                        _c("thead", [
                          _c("tr", [
                            _c("th"),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.018"))),
                            ]),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.019"))),
                            ]),
                          ]),
                        ]),
                        _vm._l(_vm.blBody, function (body, index) {
                          return _c("tbody", { key: index }, [
                            body.mrkOrg || body.mrkRvs
                              ? _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        body.ordSeqOrg == "1"
                                          ? "Mark"
                                          : "Mark Rider"
                                      )
                                    ),
                                  ]),
                                  _c("td", { staticClass: "valign_top" }, [
                                    _c("div", {
                                      staticClass: "font_12",
                                      domProps: {
                                        innerHTML: _vm._s(body.mrkOrg),
                                      },
                                    }),
                                  ]),
                                  _c("td", { staticClass: "valign_top" }, [
                                    _c("div", {
                                      staticClass: "font_12",
                                      domProps: {
                                        innerHTML: _vm._s(body.mrkRvs),
                                      },
                                    }),
                                  ]),
                                ])
                              : _vm._e(),
                            body.dscrOrg || body.dscrRvs
                              ? _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        body.ordSeqOrg == "1"
                                          ? "Description"
                                          : "Description Rider"
                                      )
                                    ),
                                  ]),
                                  _c("td", { staticClass: "valign_top" }, [
                                    _c("div", {
                                      staticClass: "font_12",
                                      domProps: {
                                        innerHTML: _vm._s(body.dscrOrg),
                                      },
                                    }),
                                  ]),
                                  _c("td", { staticClass: "valign_top" }, [
                                    _c("div", {
                                      staticClass: "font_12",
                                      domProps: {
                                        innerHTML: _vm._s(body.dscrRvs),
                                      },
                                    }),
                                  ]),
                                ])
                              : _vm._e(),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]
                : _vm._e(),
              _vm.blContainer.length > 0
                ? [
                    _c("h2", { staticClass: "content_title" }, [
                      _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.021"))),
                    ]),
                    _c("table", { staticClass: "tbl_col mt10" }, [
                      _vm._m(3),
                      _c("thead", [
                        _c("tr", [
                          _c("th", { attrs: { colspan: "2" } }),
                          _c("th", {
                            domProps: {
                              innerHTML: _vm._s(_vm.$t("msg.ONEX060P012.022")),
                            },
                          }),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.023"))),
                          ]),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.024"))),
                          ]),
                          _c("th", {
                            domProps: {
                              innerHTML: _vm._s(_vm.$t("msg.ONEX060P012.025")),
                            },
                          }),
                          _c("th", {
                            domProps: {
                              innerHTML: _vm._s(_vm.$t("msg.ONEX060P012.026")),
                            },
                          }),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.027"))),
                          ]),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.028"))),
                          ]),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.029"))),
                          ]),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.030"))),
                          ]),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.031"))),
                          ]),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.032"))),
                          ]),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.033"))),
                          ]),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.blContainer, function (container, index) {
                          return _c("tr", { key: index }, [
                            index % 2 === 0
                              ? _c("td", { attrs: { rowspan: "2" } }, [
                                  _vm._v(" " + _vm._s((index % 2) + 1) + " "),
                                ])
                              : _vm._e(),
                            _c("td", [
                              _vm._v(
                                _vm._s(index % 2 === 0 ? "Original" : "Revised")
                              ),
                            ]),
                            _c("td", [_vm._v(_vm._s(container.cntrNo))]),
                            _c("td", [_vm._v(_vm._s(container.cntrSzCd))]),
                            _c("td", [_vm._v(_vm._s(container.cntrTypCd))]),
                            _c("td", [_vm._v(_vm._s(container.cgoTypCd))]),
                            _c("td", [_vm._v(_vm._s(container.shprVanYn))]),
                            _c("td", [_vm._v(_vm._s(container.wt))]),
                            _c("td", [_vm._v(_vm._s(container.pkgQty))]),
                            _c("td", [_vm._v(_vm._s(container.sealNo1))]),
                            _c("td", [_vm._v(_vm._s(container.sealNo2))]),
                            _c("td", [_vm._v(_vm._s(container.sealNo3))]),
                            _c("td", [_vm._v(_vm._s(container.sealNo4))]),
                            _c("td", [_vm._v(_vm._s(container.sealNo5))]),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]
                : _vm._e(),
              _vm.params.caCfmYn !== "Y"
                ? _c("p", {
                    staticClass: "mt10",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("msg.ONEX060P012.034")),
                    },
                  })
                : _vm._e(),
              this.$ekmtcCommon.isNotEmpty(_vm.pentAmt) &&
              Number(_vm.pentAmt) > 0
                ? [
                    _c("p", {
                      staticClass: "mt10",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("msg.ONEX060P012.035", {
                            pentAmt: _vm.pentAmt,
                          })
                        ),
                      },
                    }),
                    _c("div", { staticClass: "mt10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.shirSacYn,
                            expression: "shirSacYn",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          id: "rdopentAmt1",
                          name: "rdopentAmt",
                          value: "01",
                        },
                        domProps: { checked: _vm._q(_vm.shirSacYn, "01") },
                        on: {
                          change: function ($event) {
                            _vm.shirSacYn = "01"
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "rdopentAmt1" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.036"))),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.shirSacYn,
                            expression: "shirSacYn",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          id: "rdopentAmt2",
                          name: "rdopentAmt",
                          value: "02",
                        },
                        domProps: { checked: _vm._q(_vm.shirSacYn, "02") },
                        on: {
                          change: function ($event) {
                            _vm.shirSacYn = "02"
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "rdopentAmt2" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.037"))),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.shirSacYn,
                            expression: "shirSacYn",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          id: "rdopentAmt3",
                          name: "rdopentAmt",
                          value: "03",
                        },
                        domProps: { checked: _vm._q(_vm.shirSacYn, "03") },
                        on: {
                          change: function ($event) {
                            _vm.shirSacYn = "03"
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "rdopentAmt3" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.038"))),
                      ]),
                      _c(
                        "a",
                        {
                          staticClass: "button sm ml10",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnInsertPay()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONEX060P012.039")))]
                      ),
                    ]),
                    _c("p", {
                      staticClass: "mt10",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("msg.ONEX060P012.040", {
                            blNo:
                              _vm.caMasterInfoList.length > 0
                                ? _vm.caMasterInfoList[0].blNo
                                : "N/A",
                          })
                        ),
                      },
                    }),
                  ]
                : _vm._e(),
              this.$ekmtcCommon.isNotEmpty(_vm.sacYn) && _vm.sacYn !== "N"
                ? _c("p", {
                    staticClass: "mt10",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("msg.ONEX060P012.041", {
                          blNo:
                            _vm.caMasterInfoList.length > 0
                              ? _vm.caMasterInfoList[0].blNo
                              : "N/A",
                        })
                      ),
                    },
                  })
                : _vm._e(),
              _vm.params.delegateYn === "Y"
                ? _c("p", { staticClass: "text_center mt10" }, [
                    _c("span", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.submitReqAgreeYn,
                            expression: "submitReqAgreeYn",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          name: "no",
                          id: "submitReqAgreeYn",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.submitReqAgreeYn)
                            ? _vm._i(_vm.submitReqAgreeYn, null) > -1
                            : _vm.submitReqAgreeYn,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.submitReqAgreeYn,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.submitReqAgreeYn = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.submitReqAgreeYn = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.submitReqAgreeYn = $$c
                            }
                          },
                        },
                      }),
                      _vm._m(4),
                    ]),
                    _vm._v(" " + _vm._s(_vm.$t("msg.ONEX060P012.042")) + " ("),
                    _c("span", { staticClass: "ml10 mr10" }, [
                      _vm._v(
                        _vm._s(
                          _vm.caMasterInfoList.length > 0
                            ? _vm.caMasterInfoList[0].actShprCstEnm
                            : "N/A"
                        )
                      ),
                    ]),
                    _vm._v(")" + _vm._s(_vm.$t("msg.ONEX060P012.043")) + " "),
                  ])
                : _vm._e(),
              _vm.params.delegateYn === "N"
                ? [
                    _vm.params.caCfmYn !== "Y"
                      ? _c("p", { staticClass: "mt10 text_right" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX060P012.044")) + " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.params.caCfmYn !== "Y"
                      ? _c("p", { staticClass: "mt10 text_right" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX060P012.045")) + " "
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("msg.ONEX060P012.046")) +
                              " " +
                              _vm._s(_vm.curDate) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
        _vm.reqAreaShow
          ? _c("div", { staticClass: "content_box" }, [
              _c("form", { attrs: { id: "frm_ca_req" } }, [
                _c("h2", { staticClass: "content_title" }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.047"))),
                ]),
                _c("table", { staticClass: "tbl_row" }, [
                  _vm._m(5),
                  _c("tbody", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.048"))),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.compName,
                              expression: "compName",
                            },
                          ],
                          attrs: { type: "text", readonly: "readonly" },
                          domProps: { value: _vm.compName },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.compName = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.049"))),
                      ]),
                      _c("td", [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.pic.picNm,
                                expression: "pic.picNm",
                              },
                            ],
                            class: [
                              _vm.pic.picNm === "direct"
                                ? ["inp_space50_2", "mr2"]
                                : "w-100",
                            ],
                            attrs: { name: "", id: "picNmSel" },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.pic,
                                    "picNm",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.fnChangeCstInfo($event)
                                },
                              ],
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.050"))),
                            ]),
                            _c("option", { attrs: { value: "direct" } }, [
                              _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.051"))),
                            ]),
                            _vm._l(_vm.cstInfoList, function (cstInfo) {
                              return _c(
                                "option",
                                {
                                  key: cstInfo.picNo,
                                  domProps: { value: cstInfo.picNm },
                                },
                                [_vm._v(" " + _vm._s(cstInfo.picNm) + " ")]
                              )
                            }),
                          ],
                          2
                        ),
                        _vm.pic.picNm === "direct"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.pic.picNmDirect,
                                  expression: "pic.picNmDirect",
                                },
                              ],
                              staticClass: "inp_space50_2",
                              attrs: { id: "picNm", type: "text" },
                              domProps: { value: _vm.pic.picNmDirect },
                              on: {
                                keyup: function ($event) {
                                  return _vm.fnValidationPicNm()
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.pic,
                                    "picNmDirect",
                                    $event.target.value
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.052"))),
                      ]),
                      _c(
                        "td",
                        [
                          _c("e-input-number", {
                            attrs: { id: "picTelNo", "is-phone": true },
                            on: {
                              input: function ($event) {
                                return _vm.fnValidationPicTelNo()
                              },
                            },
                            model: {
                              value: _vm.pic.picTelNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.pic, "picTelNo", $$v)
                              },
                              expression: "pic.picTelNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.053"))),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.pic.picEmlAddr,
                              expression: "pic.picEmlAddr",
                            },
                          ],
                          attrs: { type: "text", id: "picEmlAddr" },
                          domProps: { value: _vm.pic.picEmlAddr },
                          on: {
                            keyup: function ($event) {
                              return _vm.fnValidationPicEmlAddr()
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.pic,
                                "picEmlAddr",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm.params.delegateYn === "N" && _vm.params.caCfmYn !== "Y"
                      ? _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.054"))),
                          ]),
                          _c("td", { attrs: { colspan: "3" } }, [
                            _c(
                              "a",
                              {
                                class: [
                                  "button",
                                  "sm",
                                  _vm.fileAttachYn === "N" ? "gray" : "blue",
                                ],
                                attrs: { id: "caReqAttach", href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.openAttachPopup(
                                      "ca-request-attach-pop"
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.ONEX060P012.055")))]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm.params.delegateYn === "N" &&
                !(
                  _vm.memberDetail.userCtrCd === "KR" &&
                  _vm.$store.getters["auth/getCalcEiCatCd"] === "O"
                )
                  ? _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.ONEX060P012.056")) + " "),
                    ])
                  : _vm._e(),
                _vm.params.blIssYn === "Y" && _vm.params.surrYn !== "Y"
                  ? _c("div", { staticClass: "mt15" }, [
                      _c("h2", { staticClass: "content_title" }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.057"))),
                      ]),
                      _c("p", { staticClass: "mt10" }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.058"))),
                      ]),
                      _c(
                        "div",
                        { staticClass: "mt10", attrs: { id: "oblCatCd" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.pic.oblCatCd,
                                expression: "pic.oblCatCd",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              id: "rdo1",
                              name: "rdo",
                              value: "01",
                            },
                            domProps: {
                              checked: _vm._q(_vm.pic.oblCatCd, "01"),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  return _vm.$set(_vm.pic, "oblCatCd", "01")
                                },
                                function ($event) {
                                  return _vm.fnValidationOblCatCd()
                                },
                              ],
                            },
                          }),
                          _c("label", { attrs: { for: "rdo1" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.059"))),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.pic.oblCatCd,
                                expression: "pic.oblCatCd",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              id: "rdo4",
                              name: "rdo",
                              value: "04",
                            },
                            domProps: {
                              checked: _vm._q(_vm.pic.oblCatCd, "04"),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  return _vm.$set(_vm.pic, "oblCatCd", "04")
                                },
                                function ($event) {
                                  return _vm.fnValidationOblCatCd()
                                },
                              ],
                            },
                          }),
                          _c("label", { attrs: { for: "rdo4" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.062"))),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _c("h2", { staticClass: "content_title" }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.063"))),
                ]),
                _c("div", [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.pic.rmk,
                        expression: "pic.rmk",
                      },
                    ],
                    attrs: { id: "rmk" },
                    domProps: { value: _vm.pic.rmk },
                    on: {
                      keyup: function ($event) {
                        return _vm.fnValidationRmk()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.pic, "rmk", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "mt10 text_center" },
          [
            _vm.reqAreaShow
              ? [
                  _c(
                    "a",
                    {
                      staticClass: "button blue lg",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.fnCaDelete()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.ONEX060P012.065")))]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "button blue lg",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.fnCaSave("submit")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.ONEX060P012.066")))]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "button blue lg",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.fnCaSave("temp")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.MYIN040G010.062")))]
                  ),
                ]
              : _vm._e(),
            _c(
              "a",
              {
                staticClass: "button gray lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.openCaReqPop()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.ONEX060P012.068")))]
            ),
          ],
          2
        ),
      ]),
      _c("div", { attrs: { id: "pdf_area" } }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "160" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "160" } }),
      _c("col"),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "160" } }),
      _c("col"),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "9%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "submitReqAgreeYn" } }, [
      _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "150px" } }),
      _c("col"),
      _c("col", { staticStyle: { width: "150px" } }),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }